exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplikacje-webowe-js": () => import("./../../../src/pages/aplikacje-webowe.js" /* webpackChunkName: "component---src-pages-aplikacje-webowe-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cms-vs-strona-pisana-od-podstaw-js": () => import("./../../../src/pages/cms-vs-strona-pisana-od-podstaw.js" /* webpackChunkName: "component---src-pages-cms-vs-strona-pisana-od-podstaw-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-wybrac-najlepszy-hosting-i-domene-js": () => import("./../../../src/pages/jak-wybrac-najlepszy-hosting-i-domene.js" /* webpackChunkName: "component---src-pages-jak-wybrac-najlepszy-hosting-i-domene-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-sklepy-internetowe-js": () => import("./../../../src/pages/sklepy-internetowe.js" /* webpackChunkName: "component---src-pages-sklepy-internetowe-js" */),
  "component---src-pages-strony-niestandardowe-js": () => import("./../../../src/pages/strony-niestandardowe.js" /* webpackChunkName: "component---src-pages-strony-niestandardowe-js" */),
  "component---src-pages-strony-wordpress-js": () => import("./../../../src/pages/strony-wordpress.js" /* webpackChunkName: "component---src-pages-strony-wordpress-js" */)
}

